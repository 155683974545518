<template>
  <div>
    <GreenBlock class-name="green-section--big mb-5">
      <v-container>
        <h1 class="page-title">Dieser Code ist nicht mehr gültig</h1>
        <div class="page-subtitle max-width">
          <p>Sie haben Ihren Aktionscode bereits für eine Bestellung des eprimo SmartMeters genutzt und wir arbeiten bereits mit Hochdruck an Ihrer Bestellung.</p>
          <p>Wenn Sie Fragen haben oder Hilfe benötigen, wenden Sie sich bitte an unseren Kundenservice: <a class="text-decoration-underline"
                                                                                                            href="mailto:strompakete@eprimo.de">strompakete@eprimo.de</a></p>
          <p>Sie erreichen uns unter der Telefonnummer <span class="bold-text">0800-204011093</span></p>
        </div>
        <div class="green-section__content">
          <div class="green-section--left">
            <div>
              <p class="fw-600">Diese Rufnummer ist aus dem deutschen Festnetz kostenlos. Unsere Service-Zeiten sind montags – freitags von 9.00 – 18.00 Uhr.</p>
            </div>
            <div class="eprimo-smile-img">
              <img src="~@/assets/images/eprimo-face-3.svg" alt="eprimo-face">
            </div>
          </div>
          <div class="text-center-mobile">
            <a class="btn" href="https://www.eprimo.de/">eprimo.de besuchen</a>
          </div>
        </div>
      </v-container>
    </GreenBlock>
    <FAQ/>
  </div>
</template>

<script>
import GreenBlock from "@/components/GreenBlock";
import FAQ from "@/components/FAQSmartOrder";

export default {
  name: 'ErrorOrder',
  components: {
    GreenBlock,
    FAQ
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'login' || to.name === 'meter_ordering_login') {
      next();
    } else {
      this.$router.push({name: 'login'});
    }
  }
}
</script>

<style lang="scss" scoped>
.page-title {
  margin-bottom: 25px;
}

p {
  margin-bottom: 22px;
}

.fw-600 {
  font-weight: 600;
  margin-bottom: 40px;
  margin-top: -5px;
}

.green-section--right img {
  width: 200px;
}

.green-section.green-section--big .green-section--right {
  margin: -10px 0px -50px 0;
}

.v-application .green-section a {
  font-weight: bold;
}

.bold-text {
  font-weight: bold;
}

.page-subtitle p {
  margin-bottom: 25px;
}

.green-section--left p {
  padding-right: 200px;
}

.eprimo-smile-img {
  float: right;

  img {
    width: 200px;
    margin-top: -100px;
  }
}

.green-section__content {
  display: block;
}

@media (max-width: 767px) {

  .green-section--left p {
    padding-right: 0;
  }

  .page-title {
    font-size: 20px;
  }

  .page-subtitle {
    font-size: 14px;
  }

  .text-center-mobile a {
    width: 100%;
  }

  .eprimo-smile-img img {
    width: 170px;
    margin: -55px -30px 0px 0px;
  }

  .green-section__content .green-section--left {
      display: flex;
  }

  .green-section__content .btn{
    margin-top: -30px;
    width: 100%;
  }

}

</style>
